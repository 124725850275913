module.exports = {
  // The domain name of your Prismic repository. This can be found in the URL of
  // your repository.
  //
  // Example: 'my-repo' if your Prismic repository URL is 'enterprof.prismic.io'.
  prismicRepo: 'enterprof',

  // The default language for content in your Prismic repository.
  defaultLanguage: 'en-eu',

  // All available languages for content in your Prismic repository.
  langs: ['ru', 'es-es'],
}
